export const storageD = [
  {
    id: 1,
    name: "Bar Ombori",
    total: 10,
    remain: 10,
    unit: "kg",
    profit: -80,
    items: { a_price: 230, qty: 21 },
    category: "test 1",
    data: [
      {
        id: "355335",
        name: "Murod",
        password: "123456",
        department: "kassir",
        code: "123456",
        active: true,
      },
      {
        id: "765433",
        name: "Saidakbar",
        password: "123456",
        department: "waiter",
        code: "123456",
      },
      {
        id: "234567",
        name: "Asadbek",
        password: "123456",
        department: "cheff",
        code: "123456",
      },
      {
        id: "355335",
        name: "Murod",
        password: "123456",
        department: "kassir",
        code: "123456",
        active: true,
      },
      {
        id: "765433",
        name: "Saidakbar",
        password: "123456",
        department: "waiter",
        code: "123456",
        active: true,
      },
      {
        id: "234567",
        name: "Asadbek",
        password: "123456",
        department: "cheff",
        code: "123456",
      },
    ],
    dep: "Bar",
  },
  {
    id: 2,
    name: "Oshxona Ombori",
    total: 10,
    remain: 10,
    unit: "kg",
    profit: 100,
    items: { a_price: 230, qty: 21 },
    category: "test 2",
    data: [
      {
        id: "355335",
        name: "Murod",
        password: "123456",
        department: "kassir",
        code: "123456",
        active: true,
      },
      {
        id: "765433",
        name: "Saidakbar",
        password: "123456",
        department: "waiter",
        code: "123456",
      },
      {
        id: "234567",
        name: "Asadbek",
        password: "123456",
        department: "cheff",
        code: "123456",
      },
      {
        id: "355335",
        name: "Murod",
        password: "123456",
        department: "kassir",
        code: "123456",
        active: true,
      },
      {
        id: "765433",
        name: "Saidakbar",
        password: "123456",
        department: "waiter",
        code: "123456",
        active: true,
      },
      {
        id: "234567",
        name: "Asadbek",
        password: "123456",
        department: "cheff",
        code: "123456",
      },
    ],
    dep: "Oshxona",
  },
  {
    id: 3,
    name: "Salat Ombori",
    total: 10,
    remain: 10,
    unit: "l",
    profit: -10,
    items: { a_price: 230, qty: 21 },
    category: "test 3",
    data: [
      {
        id: "355335",
        name: "Murod",
        password: "123456",
        department: "kassir",
        code: "123456",
        active: true,
      },
      {
        id: "765433",
        name: "Saidakbar",
        password: "123456",
        department: "waiter",
        code: "123456",
      },
      {
        id: "234567",
        name: "Asadbek",
        password: "123456",
        department: "cheff",
        code: "123456",
      },
      {
        id: "355335",
        name: "Murod",
        password: "123456",
        department: "kassir",
        code: "123456",
        active: true,
      },
      {
        id: "765433",
        name: "Saidakbar",
        password: "123456",
        department: "waiter",
        code: "123456",
        active: true,
      },
      {
        id: "234567",
        name: "Asadbek",
        password: "123456",
        department: "cheff",
        code: "123456",
      },
    ],
    dep: "Salat",
  },
  {
    id: 4,
    name: "Idish Ombori",
    total: 10,
    remain: 10,
    unit: "kg",
    profit: 100,
    items: { a_price: 230, qty: 21 },
    category: "test 4",
    data: [
      {
        id: "355335",
        name: "Murod",
        password: "123456",
        department: "kassir",
        code: "123456",
        active: true,
      },
      {
        id: "765433",
        name: "Saidakbar",
        password: "123456",
        department: "waiter",
        code: "123456",
      },
      {
        id: "234567",
        name: "Asadbek",
        password: "123456",
        department: "cheff",
        code: "123456",
      },
      {
        id: "355335",
        name: "Murod",
        password: "123456",
        department: "kassir",
        code: "123456",
        active: true,
      },
      {
        id: "765433",
        name: "Saidakbar",
        password: "123456",
        department: "waiter",
        code: "123456",
        active: true,
      },
      {
        id: "234567",
        name: "Asadbek",
        password: "123456",
        department: "cheff",
        code: "123456",
      },
    ],
    dep: "Idish",
  },
];
