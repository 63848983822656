const user = JSON?.parse(localStorage.getItem("user"))?.user || null;

export const reActive = (state = {}, action) => {
  switch (action.type) {
    case "ADD_ING":
      return action?.payload;
    default:
      return state;
  }
};
const today = new Date().toISOString().split("T")[0];

const initialState = {
  id: null,
  name: "",
  group: "",
  ingredient_group: "",
  category: "",
  date: today,
  storage: "",
  description: "",
  amount: "",
  order: "",
  ingredient: "",
  ingredient_id: "",
  responsible: "",
  type: "",
  fullname: "",
  passport: "",
  SNILS: "",
  code: "",
  INN: "",
  registered_address: "",
  residence_address: "",
  number: "",
  fullOrganizationName: "",
  shortOrganizationName: "",
  issued_by: "",
  KPP: "",
  OKPO: "",
  ORGN: "",
  Yuridik_address: "",
  ActualAddress: "",
  unit: "",
  price: "",
  payment_type: "",
  res_id: user?.id,
};

export const reActiveThing = (state = initialState, action) => {
  switch (action.type) {
    case "ACTIVE_THING":
      return { ...state, ...action.payload };
    case "PASSIVE_THING":
      return initialState;
    default:
      return state;
  }
};

export const reStorageId = (state = "", action) => {
  switch (action.type) {
    case "STORAGE_ID":
      return action.payload;
    default:
      return state;
  }
};

export const reActiveSt_id = (state = null, action) => {
  switch (action.type) {
    case "ACTIVE_ST_ID":
      return action.payload;
    default:
      return state;
  }
};

export const reActiveB = (state = {}, action) => {
  switch (action?.type) {
    case "ADD_BILL":
      return action?.payload;
    case "REMOVE_BILL":
      return {};
    default:
      return state;
  }
};

const df_s = { worker: user?.worker_name || user?.username, worker_id: user?.worker_id || user?.id, time: today, };

export const reFormValues = (state = { st: false, vl: df_s, }, action) => {
  switch (action?.type) {
    case "A_V":
      return { st: true, vl: { ...state?.vl, ...action?.payload } };
    case "A_F_V":
      return { st: false, vl: action?.payload };
    case "R_V":
      return { st: false, vl: df_s };
    default:
      return state;
  }
};

export const reProduct = (state = [], action) => {
  switch (action?.type) {
    case "A_P":
      return [...state, action?.payload];
    case "R_P":
      return state.filter((i) => i.id !== action?.payload);
    case "CH_P":
      return state.map((i) =>
        i.id === action?.payload.id ? action?.payload : i
      );
    case "CLEAR_PRODUCT":
      return [];
    default:
      return state;
  }
};

export const acActive = (payload) => ({ type: "ADD_ING", payload });
export const acActiveThing = (payload) => ({ type: "ACTIVE_THING", payload });
export const acPassiveThing = (payload) => ({ type: "PASSIVE_THING", payload });
export const acStorageId = (payload) => ({ type: "STORAGE_ID", payload });
export const acActiveSt_id = (payload) => ({ type: "ACTIVE_ST_ID", payload });
export const acAddBill = (payload) => ({ type: "ADD_BILL", payload });
export const acRemoveBill = (payload) => ({ type: "REMOVE_BILL", payload });
export const acFormValues = (v, p) => ({ type: v, payload: p });
