import React, { useState, useEffect } from "react";
import "../cash-universal.css";
import { useSelector, useDispatch } from "react-redux";
import { LoadingBtn } from "../../../components/loading/loading";
import { NumericFormat } from "react-number-format";
import AnimatedNumber from "animated-number-react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { useFetchDataQuery } from "../../../service/fetch.service";
import { CalculateTotal, CalculateTotalByLine, CalculateTotalQuantity } from "../../../service/calc.service";
import { acNavStatus } from "../../../redux/navbar.status";
import { UniversalFilterBox } from "../../../components/filter/filter";

export const TransactionRapor = () => {
  const [sort, setSort] = useState({ id: null, state: false });
  const [details, setDetails] = useState({ ind: null, title: "" });
  const { date } = useSelector((state) => state.uSearch);
  const dispatch = useDispatch();
  const { data: trData = [], isLoading } = useFetchDataQuery({ url: `get/transactionsTable/${date?.start}/${date?.end}`, tags: ["transaction-report"], });
  const { data: trExpData = [] } = useFetchDataQuery({ url: `get/expenseTransactions/${date?.start}/${date?.end}`, tags: ["transaction-report"], });
  const { data: trIncData = [] } = useFetchDataQuery({ url: `get/incomeTransactions/${date?.start}/${date?.end}`, tags: ["transaction-report"], });
  const { data: balance = [] } = useFetchDataQuery({ url: `get/balance/${date?.start}`, tags: ["transaction-report"], });
  useEffect(() => { dispatch(acNavStatus([0, 6, 7, 15])); }, [dispatch]);
  let total = 0;
  console.log(details);

  // console.log(trData?.data);
  const headerData = [
    { name: "Turi", size: "11.3%" },
    { name: "Naqd pul", size: "10.7%" },
    { name: "Hisob raqami", size: "10.7%" },
    { name: "Plastik karta", size: "10.7%" },
    { name: "Qarz", size: "10.7%" },
    { name: "Depozit", size: "10.7%" },
    { name: "To'lanmagan", size: "10.7%" },
    { name: "Online to'lov", size: "10.7%" },
    { name: "Jami", size: "10.7%" },
  ];

  const displayKeys = [
    { name: "type", size: "11.3%" },
    { name: "cash", size: "10.7%", position: "flex-end" },
    { name: "credit", size: "10.7%", position: "flex-end" },
    { name: "click", size: "10.7%", position: "flex-end" },
    { name: "dept", size: "10.7%", position: "flex-end" },
    { name: "depozit", size: "10.7%", position: "flex-end" },
    { name: "not_paid", size: "10.7%", position: "flex-end" },
    { name: "online_income", size: "10.7%", position: "flex-end" },
  ];

  const displayTotalKeys = [
    { name: "type", tick: "Jami balans", size: "11.3%" },
    { name: "cash", size: "10.7%", position: "flex-end", flex: true },
    { name: "credit", size: "10.7%", position: "flex-end", flex: true },
    { name: "click", size: "10.7%", position: "flex-end", flex: true },
    { name: "dept", size: "10.7%", position: "flex-end", flex: true },
    { name: "depozit", size: "10.7%", position: "flex-end", flex: true },
    { name: "not_paid", size: "10.7%", position: "flex-end", flex: true },
    { name: "online_income", size: "10.7%", position: "flex-end", flex: true },
  ];

  const report = {
    startOfDay: balance?.data,
    income: CalculateTotal(trIncData?.data, "transactions"),
    expense: CalculateTotal(trExpData?.data, "transactions"),
    balance: CalculateTotal(trIncData?.data, "transactions") - CalculateTotal(trExpData?.data, "transactions"),
    debt: CalculateTotal(trIncData?.data, "transactions") - CalculateTotal(trExpData?.data, "transactions"),
    endOfDay: CalculateTotal(trIncData?.data, "transactions") - CalculateTotal(trExpData?.data, "transactions") + balance?.data,
  };

  return (
    <div className="storage_container">
      <UniversalFilterBox />
      <div className="storage_body">
        <p>
          <span>Tranzaksiyalar hisoboti</span>
        </p>
        <div className="storage_body_item _item-header">
          <p style={{ inlineSize: "var(--univslH)" }}>№</p>
          {headerData?.map((item, index) => {
            return (
              <p
                key={index}
                style={{
                  "--data-line-size": item?.size,
                  justifyContent: "center",
                }}
                onClick={() => {
                  setSort({ id: index, state: !sort.state });
                }}
                aria-label="sort data down of top or top of down">
                {item?.name}{" "}
                {sort.id === index ? (sort.state ? (<RiArrowUpSLine />) : (<RiArrowDownSLine />)) : null}
              </p>
            );
          })}
        </div>
        <div className="storage_body_box">
          {isLoading ? (
            <span className="loader_box relative">
              <LoadingBtn />
            </span>
          ) : (
            trData?.data?.map((item, index) => {
              return (
                <div className={"storage_body__box"} key={index}>
                  <div
                    className={"storage_body_item"}
                    key={item?.id}>
                    <p style={{ inlineSize: "var(--univslH)" }}>{index + 1}</p>
                    {displayKeys?.map((key, index) => {
                      return (
                        <p
                          key={index}
                          style={{
                            "--data-line-size": key?.size,
                            justifyContent: key?.position || "flex-start",
                          }}>
                          {item[key?.name] || 0}
                        </p>
                      );
                    })}
                    <p
                      style={{
                        "--data-line-size": "10.7%",
                        justifyContent: "flex-end",
                      }}>
                      {CalculateTotalByLine(trData?.data, "type")}
                    </p>
                  </div>
                </div>
              );
            })
          )}
          <div className={"storage_body_item"} style={{ background: "#3339" }}>
            <p></p>
            {displayTotalKeys?.map((displayKey, index) => (
              <p
                key={index}
                style={{
                  "--data-line-size": displayKey.size,
                  justifyContent: displayKey.position || "flex-start",
                }}>
                {displayKey.tick}
                {displayKey.flex &&
                  CalculateTotalQuantity(trData?.data, displayKey?.name)}
              </p>
            ))}
            <p
              style={{
                "--data-line-size": "10.7%",
                justifyContent: "flex-end",
              }}>
              {(total += CalculateTotalByLine(trData?.data, "type"))}
            </p>
          </div>
          <div className="storage_body_item" style={{ background: "#3339" }}>
            <p style={{ border: "none" }}></p>
            <p
              style={{
                "--data-line-size": "90%",
                justifyContent: "flex-start",
              }}>
              Oxirgi yangilanish - {trData?.time}
            </p>
          </div>
        </div>
        <div className="storage_body_report">
          <div className="report_item">
            <pre className="_header">
              <p>Kirim</p>
              <AnimatedNumber
                value={CalculateTotal(trIncData?.data, "transactions")}
                formatValue={(value) =>
                  value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")
                }
              />
            </pre>
            {trIncData?.data?.map((inner, index) => {
              return (
                <div className="_body__item" key={index + 1}>
                  <p>{inner?.transaction_group}</p>
                  <div className="_body__item_details">
                    {inner?.transactions?.map((tr, ind) => (
                      <>
                        <div className="_details__item" key={ind + 121}>
                          <p>{tr?.payment_type}</p>
                          <NumericFormat
                            value={CalculateTotalQuantity(
                              tr?.details,
                              "amount"
                            )}
                            displayType="text"
                            thousandSeparator=" "
                          />
                          <p
                            style={{
                              "--data-line-size": "15%",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setDetails({ ind: ind, title: tr?.payment_type })
                            }>
                            <u>tafsilot</u>
                          </p>
                        </div>
                        {details.ind === ind && details.title === tr?.payment_type && (
                          <div className="_details__item-info">
                              {tr?.details.map((item, id) => (
                                <div className="w100 df aic jcsb __item-info-piece" key={id}>
                                  <p>{item.description}</p><p>{item.amount}</p>
                                </div>
                              ))}
                            </div>
                          )}
                      </>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="report_item">
            <pre className="_header">
              <p>Chiqim</p>
              <AnimatedNumber
                value={CalculateTotal(trExpData?.data, "transactions")}
                formatValue={(value) =>
                  value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")
                }
              />
            </pre>
            {trExpData?.data?.map((inner, index) => {
              return (
                <div className="_body__item" key={index + 543}>
                  <p>{inner?.transaction_group}</p>
                  <div className="_body__item_details">
                    {inner?.transactions?.map((tr, ind) => (
                      <>
                        <div className="_details__item" key={ind + 121}>
                          <p>{tr?.payment_type}</p>
                          <NumericFormat
                            value={CalculateTotalQuantity(
                              tr?.details,
                              "amount"
                            )}
                            displayType="text"
                            thousandSeparator=" "
                          />
                          <p
                            style={{
                              "--data-line-size": "15%",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setDetails({ ind: ind, title: tr?.payment_type })
                            }>
                            <u>tafsilot</u>
                          </p>
                        </div>
                        {details.ind === ind && details.title === tr?.payment_type && (
                          <div className="_details__item-info">
                            {tr?.details.map((item, id) => (
                              <div className="w100 df aic jcsb __item-info-piece" key={id}>
                                <p>{item.description}</p><p>{item.amount}</p>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="report_item big_fs">
            <div className="report_daily">
              <p>Kunning boshida</p>
              <i></i>
              <AnimatedNumber
                value={report?.startOfDay}
                formatValue={(value) =>
                  value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")
                }
              />
            </div>
            <div className="report_daily">
              <p>Kirim</p>
              <i></i>
              <AnimatedNumber
                value={report?.income}
                formatValue={(value) =>
                  value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")
                }
              />
            </div>
            <div className="report_daily">
              <p>Chiqim</p>
              <i></i>
              <AnimatedNumber
                value={report?.expense}
                formatValue={(value) =>
                  value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")
                }
              />
            </div>
            <div className="report_daily">
              <p>Kunning balansi</p>
              <i></i>
              <AnimatedNumber
                value={report?.balance}
                formatValue={(value) =>
                  value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")
                }
              />
            </div>
            <div className="report_daily">
              <p>Kunning oxirida</p>
              <i></i>
              <AnimatedNumber
                value={report?.endOfDay}
                formatValue={(value) =>
                  value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")
                }
              />
            </div>
            <div className="report_daily pdt">
              <p>Qarz</p>
              <i></i>
              <AnimatedNumber
                value={report?.debt || 0}
                formatValue={(value) =>
                  value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")
                }
              />
            </div>
            <div className="report_daily pdt">
              <p>To'langan qarzlar</p>
              <i></i>
              <AnimatedNumber
                value={report?.paid_debt || 0}
                formatValue={(value) =>
                  value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")
                }
              />
            </div>
          </div>
          <div className="report_item">
            <h3>Bo'limlar</h3>
            <div className="report_daily">
              <p>Oshxona</p>
              <i></i>
              <NumericFormat
                value={324234}
                displayType="text"
                thousandSeparator=","
              />
            </div>
            <div className="report_daily">
              <p>Bar</p>
              <i></i>
              <NumericFormat
                value={324234}
                displayType="text"
                thousandSeparator=","
              />
            </div>
            <div className="report_daily">
              <p>Ombor</p>
              <i></i>
              <NumericFormat
                value={324234}
                displayType="text"
                thousandSeparator=","
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
